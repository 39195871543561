import React, {useEffect, useState, useRef} from "react";
import * as Body from './Styles'

import * as ConfigModals from '../config/modals/Modals'

import {Button} from "../../components/Button/Styles";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../utils/redux/store";
import disconnectedIcon from '../../assets/icons/disconnected.svg'

import * as Modals from './modals/Modals'
import * as functions from '../../utils/functions/functions'

import warningIcon from '../../assets/icons/warning-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import bellIconInactive from '../../assets/icons/bell-icon.svg'
import chartIconInactive from '../../assets/icons/chart-icon.svg'
import homeIconInactive from '../../assets/icons/home-icon.svg'
import bellIconActive from '../../assets/icons/bell-icon-active.svg'
import chartIconActive from '../../assets/icons/chart-icon-active.svg'
import homeIconActive from '../../assets/icons/home-icon-active.svg'
import bombaIcon from '../../assets/icons/bomba-icon.svg'
import transmissorIcon from '../../assets/icons/transmissor-icon.svg'
import valvulaIcon from '../../assets/icons/valvula-icon.svg'
import oleoIcon from '../../assets/icons/oleo-icon.svg'
import axios from "axios";
import * as Types from '../../utils/redux/types'
import Loading from "../../components/Loading/Loading";
import { dataMoenda } from "../../utils/redux/data";

type TernosProps = {
    terno_id: number,
    nome_terno: string
}

const Status = {
    OK: 1,
    WARNING: 2,
}

const initialData: Types.MoendaState = {
    desempenho: 0,
    ternos: dataMoenda[0].ternos,
    nivel_acesso: 1,
    intervencao: 2,
    nome_usina: '',
    nuvem_status: 1,
    unidadeHidraulica: {
        status: 1,
        manual: false,
        oleo: {
            temperatura: 26,
            trans_fil_suj_1: 1,
            trans_fil_suj_2: 0, 
            trans_umidade: 0,
            alarme_conjunto_filtro_1: 1,
            alarme_conjunto_filtro_2: 0,
            situacao: 0
        },
        bomba: {
            transmissor_pressao_2: 0,
            transmissor_pressao_1: 0,
            transmissor_pressao_3: 0,
            data: '',
            situacao: 0,
            autoteste_bomba_1: 0,
            autoteste_bomba_2: 0,
            autoteste_bomba_3: 0,
            pressao_max: 0
        },
        valvulas: [],
        transmissores: []
    }

}

const SituacaoBomba = {
    OK: 0,
    PROBLEMA_BOMBA_1: 1,
    PROBLEMA_BOMBA_2: 2,
    PROBLEMA_BOMBA_1_E_2: 3,
    PROBLEMA_BOMBA_3: 4,
    PROBLEMA_TRES_BOMBAS: 5,
    PROBLEMA_BOMBA_1_E_3: 6,
    PROBLEMA_BOMBA_2_E_3: 7
}

const Moenda = () => {
    const navigate = useNavigate()


    const [params] = useSearchParams()
    const token = params.get('token')
    const nome_usina = params.get('nome-usina')
    const [loading, toggleLoading] = useState(true)
    
    const dispatch = useDispatch()
    const [data, setData] = useState<Types.MoendaState>(initialData)
    const timer = useRef(0)
    const [seeTime, setSeeTime] = useState(20)
    const [seeIsRequesting, toggleIsRequesting] = useState(false)
    const isRequesting = useRef(false)

    const [chartIcon, toggleChart] = useState(chartIconInactive)
    const [bellIcon, toggleBell] = useState(bellIconInactive)
    const [homeIcon, toggleHome] = useState(homeIconInactive)

    const [hasNotification, toggleHasNotification] = useState(false)

    const [modalNotifications, toggleModalNotifications] = useState(false)
    const [modalRegisterIntervention, toggleModalRegisterIntervention] = useState(false)
    const [modalInterventions, toggleModalInterventions] = useState(false)
    const [modalPerformance, togglePerformance] = useState(false)
    const [modalParametrizationPT, toggleModalParametrizationPT] = useState(false)
    const [modalParametrizationUH, toggleModalParametrizationUH] = useState(false)
    const [modalParametrizationUH2, toggleModalParametrizationUH2] = useState(false)
    const [modalLimits, toggleModalLimits] = useState(false)
    const [changeUser, toggleChangeUser] = useState(false)
    const [registerUser, toggleRegisterUser] = useState(false)
    const [isUH2, toggleIsUH2] = useState(false)

    const [openBomb,toggleOpenBomb] = useState(false)
    const [openOil, toggleOpenOil] = useState(false)
    const [openValve, toggleOpenValve] = useState(false)
    const [openTransmitter, toggleOpenTransmitter] = useState(false)
    
    const [ternoId, setTernoId] = useState<number>(0)
    const [terno, setTerno] = useState<Types.Terno>()
    const [ternos, setTernos] = useState<TernosProps[]>()
    const [visualizeTerno, toggleVisualizeTerno] = useState(false)

    const [expandUHTitle, toggleUHTitle] = useState(false)

    useEffect(()=>{
        isRequesting.current = true
        toggleIsRequesting(true)
        axios.get('https://www.dhm.dinamoautomacao.com.br/api/moenda' + '?token='+functions.ReplaceSpecialCharacters(token!), {withCredentials: true})
            .then((response)=>{
                setData(response.data)
                toggleLoading(false)
                response.data.unidadeHidraulica2?toggleIsUH2(true):toggleIsUH2(false)
            })
            .catch((error)=>{
                console.log(error)
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
            .finally(()=>{
                isRequesting.current = false
                toggleIsRequesting(false)
            })

        const interval = setInterval(()=>{
            if(isRequesting.current)
            return
            
            timer.current -= 1
            setSeeTime(old => old - 1)

            if(timer.current == 0){
                isRequesting.current = true
                toggleIsRequesting(true)
                axios.get('https://www.dhm.dinamoautomacao.com.br/api/moenda' + '?token='+token, {withCredentials: true})
                    .then((response)=>{
                        setData(response.data)
                        toggleLoading(false)
                        response.data.unidadeHidraulica2?toggleIsUH2(true):toggleIsUH2(false)
                    })
                    .catch((error)=>{
                        toggleLoading(false)
                        functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                    })
                    .finally(()=>{
                        toggleIsRequesting(false)
                        isRequesting.current = false
                    })                
            }else if(timer.current == -1){
                setSeeTime(20)
                toggleIsRequesting(false)
                timer.current = 20
            }
            
        }, 1000)

        return function cleanup(){
            clearInterval(interval)
        }

    },[])

    const selectTernoById = (id: number, hasTernos?: {terno_id: number, nome_terno: string}[]) => {
        let ternoSelected = data.ternos.find(item => item.terno_id === id)
        if(!ternoSelected){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Terno não encontrado'})
            return
        }
        setTerno(ternoSelected)
        if(hasTernos)setTernos(hasTernos)
        toggleVisualizeTerno(true)
    }

    const getTextBomb = (value: number) => {
        switch(value){
            case SituacaoBomba.OK:
                return "Bombas em funcionamento"
            case SituacaoBomba.PROBLEMA_BOMBA_1:
                return "Bomba 1 parada"
            case SituacaoBomba.PROBLEMA_BOMBA_2:
                return "Bomba 2 parada"
            case SituacaoBomba.PROBLEMA_BOMBA_3:
                return "Bomba 3 parada"
            case SituacaoBomba.PROBLEMA_BOMBA_1_E_2:
                return "Bombas 1 e 2 paradas"
            case SituacaoBomba.PROBLEMA_BOMBA_1_E_3:
                return "Bombas 1 e 3 paradas"   
            case SituacaoBomba.PROBLEMA_BOMBA_2_E_3:
                return "Bombas 2 e 3 paradas" 
            case SituacaoBomba.PROBLEMA_TRES_BOMBAS:
                return "Bombas 1, 2 e 3 paradas" 
            default:
                return "Erro ao encontrar status"
        }
    }

    const getAlertsOil = (UH: Types.UnidadeHidraulicaType) => {
        let vet = []

        if(UH.oleo.temperatura >= 65){
            vet.push('Temperatura acima da máxima. Bombas bloqueadas para impedir danos ao sistema.')
        }else if(UH.oleo.temperatura >= 60){
            vet.push('Temperatura próxima da máxima. Verificar funcionamento o trocador de óleo.')
        }else if((UH.oleo.temperatura < 0)){
            vet.push('Problema identificado na leitura do valor de temperatura do óleo.')
        }

        if(UH.oleo.temperatura > 25 && UH.oleo.trans_fil_suj_1 >= 2){
            vet.push('Nível de saturação do filtro 1 atingido. Trocar imediatamente.')
        }else if(UH.oleo.temperatura > 25 && UH.oleo.trans_fil_suj_1 >= 0.5){
            vet.push('Nível de saturação do filtro 1 próximo ao limite. Programar troca.')
        }
        if(UH.oleo.temperatura > 25 && UH.oleo.trans_fil_suj_2 >= 2){
            vet.push('Nível de saturação do filtro 2 atingido. Trocar imediatamente.')
        }else if(UH.oleo.temperatura > 25 && UH.oleo.trans_fil_suj_2 >= 0.5){
            vet.push('Nível de saturação do filtro 2 próximo ao limite. Programar troca.')
        }
        if(UH.oleo.trans_umidade > 60){vet.push('Umidade em nível crítico. Providenciar imediatamente a remoção do excesso de umidade.')}
        else if(UH.oleo.trans_umidade > 40){vet.push('Nível de umidade em estado de atenção. Providenciar remoção de excesso de umidade.')}
        if(UH.oleo.alarme_conjunto_filtro_1 === 2){vet.push('Possível problema no conjunto do filtro 1.')}
        if(UH.oleo.alarme_conjunto_filtro_2 === 2){vet.push('Possível problema no conjunto do filtro 2.')}

        return <>
            {vet.length > 0?<><Body.MoendaTitle style={{marginBottom: '5px'}}><strong style={{color: "yellow", fontWeight: 300}}>⚠ </strong>Alertas</Body.MoendaTitle>
                {vet.map((item, index)=>{
                    return <Body.MoendaText key={index.toString()}>● {item}</Body.MoendaText>
                })}</>:null}
        </>

    }

    const getTernos = (array: {terno_id: number, nome_terno: string}[]) => {
        return <div style={{width: '100%', display: "flex", flexWrap: 'wrap', justifyContent: "space-between"}}>
            {array.map((item, index) => {
            return <Button key={index.toString()} style={{width: "49%", marginBottom: '10px'}}
                    onClick={()=>selectTernoById(item.terno_id, array)}>
                {item.nome_terno}
            </Button>})}
        </div>
    }

    return <Body.Container>
        <Body.Nav>
            <Body.NavTitleContent>
                <Body.NavTitle>
                    {nome_usina}
                </Body.NavTitle>
            </Body.NavTitleContent>
            <Body.NavButtons>
                <Button onMouseEnter={()=>toggleChart(chartIconActive)} onMouseLeave={()=>toggleChart(chartIconInactive)}
                    onClick={()=>navigate("/charts?acess="+data.nivel_acesso+"&token="+token)}>
                    <Body.BtnIcon src={chartIcon} />
                </Button>
                {/* <Button onMouseEnter={()=>toggleBell(bellIconActive)} onMouseLeave={()=>toggleBell(bellIconInactive)}
                    onClick={()=>toggleModalNotifications(true)}>
                    <Body.BtnIcon src={bellIcon} />
                    {hasNotification?<Body.CircleRed />:null}
                </Button> */}
                <Button  onMouseEnter={()=>toggleHome(homeIconActive)} onMouseLeave={()=>toggleHome(homeIconInactive)}
                    onClick={()=>navigate(-1)}>
                    <Body.BtnIcon src={homeIcon} />
                </Button>
                {/* <Button onClick={()=>navigate('/Config')}>
                   {user.nome}
                </Button> */}

            </Body.NavButtons>
        </Body.Nav>

        {data.nuvem_status === 2?<Body.Content style={{justifyContent: 'center', flexDirection: 'column', gridGap: '10px 0px'}}>
            <Body.Circle color="#FFF"  style={{height: '120px', width: '120px'}}>

            <Body.IconTitle src={disconnectedIcon} style={{height: '80px', width: '80px'}}/>
            </Body.Circle>
            <Body.MoendaText>A conexão com o DHM foi perdida.</Body.MoendaText>
        </Body.Content>:
        <Body.Content>
            <Body.HalfContent percentage={49.5}  style={{display: 'flex', flexDirection: "column", gridGap: '10px 0px'}}>
                <Body.Card >
                    <Body.Row style={{gridGap: '0px 10px', marginBottom: '5px'}} >
                        <Body.MoendaTitle style={{marginBottom: '15px'}}>
                            Desempenho
                        </Body.MoendaTitle>
                    </Body.Row>
                    <Body.Row style={{width: '100%', marginBottom: '15px', justifyContent: 'space-around'}}>
                        <Body.HalfContent percentage={45.9}>
                            <Body.TitleUHBG onMouseEnter={()=>toggleUHTitle(true)} onMouseLeave={()=>toggleUHTitle(false)}>
                                <Body.MoendaTitle style={{marginBottom: '0px', marginRight: '5px', fontSize: expandUHTitle?14:18}}>
                                    Unidade Hidráulica
                                </Body.MoendaTitle>
                                <Body.CardTitle color="#FFF" style={{borderWidth: 1.5}} >
                                        <Body.MoendaText style={{marginBottom: '0px', color: "#fff", fontWeight: 600,
                                            width: expandUHTitle?85:12, overflow: "hidden", textAlign: 'center'}}>
                                            {data.unidadeHidraulica.manual?"Manual":"Automática"}
                                        </Body.MoendaText>
                            </Body.CardTitle>
                            </Body.TitleUHBG>
                            <Body.MoendaTitle style={{marginBottom: '5px', fontSize: 15,
                                fontWeight: 500, paddingLeft: '15px', paddingRight: '15px'}}>
                                {functions.getStatusUH(data.unidadeHidraulica.status)?.msg}
                            </Body.MoendaTitle>
                        </Body.HalfContent>
                        <Body.HalfContent percentage={45.9}>
                            <Body.TitleUHBG>
                                <Body.MoendaTitle style={{marginBottom: '0px'}}>Ternos</Body.MoendaTitle>
                            </Body.TitleUHBG>
                            <Body.Row style={{width: '100%', flexWrap: 'wrap', gridGap: '5px 5px', paddingLeft: '15px', 
                                paddingRight: '15px'}}>
                                {data.ternos.map((item, index)=>{
                                    return <Body.CardTitle color="#17212E" key={index.toString()} 
                                        style={{height: '25px', borderRadius: '12.5px', cursor: 'pointer',boxSizing: 'border-box',
                                         backgroundColor: "#17212E", paddingLeft: 10, paddingRight: 10}}
                                         onClick={()=>selectTernoById(item.terno_id)}>
                                            <Body.MoendaText style={{marginBottom: '0px', color: "#fff", marginRight: '5px'}}>
                                                {item.nome_terno}
                                            </Body.MoendaText>
                                            {!item.funcionando?<Body.CircleTitle color={'#F03F3F'} 
                                                style={{width: '14px', height: '14px', backgroundColor: '#F03F3F'}}  >
                                                <Body.MoendaText style={{marginBottom: '0px', fontSize: '12px', fontWeight: 700}}>P</Body.MoendaText>
                                            </Body.CircleTitle>:null}
                                            {item.funcionando && item.manual?<Body.CircleTitle color={'#FFF'} 
                                                style={{width: '14px', height: '14px'}}  >
                                                <Body.MoendaText style={{marginBottom: '0px', fontSize: '12px', fontWeight: 700}}>M</Body.MoendaText>
                                            </Body.CircleTitle>:null}
                                            {item.funcionando && !item.manual?<Body.CircleTitle color={functions.getStatusTerno(item.status).color} 
                                                style={{width: '14px', height: '14px'}}  >
                                                <Body.IconTitle style={{width: '8px', height: '8px'}} src={functions.getStatusTerno(item.status).icon} />
                                            </Body.CircleTitle>:null}
                                        </Body.CardTitle>
                                })}
                            </Body.Row>
                        </Body.HalfContent>
                    </Body.Row>
                    <Button onClick={()=>togglePerformance(true)}>
                        Visualizar desempenho dos ternos
                    </Button>
                </Body.Card>
                <Body.Card >
                    <Body.Row style={{gridGap: '0px 10px'}}>
                        <Body.MoendaTitle>
                            Intervenções
                        </Body.MoendaTitle>
                    </Body.Row>  
                        <Body.NavButtons style={{gridGap: '0px 30px'}} >
                            <Button onClick={()=>toggleModalRegisterIntervention(true)}
                                style={{width: '200px'}}>
                                Cadastrar
                            </Button>
                            <Button onClick={()=>toggleModalInterventions(true)}
                                style={{width: '220px'}}>
                                Listar Intervenções <strong style={{color: "#E5D34A", fontWeight: 300}}>{data.intervencao === Status.OK?null:"⚠"}</strong>
                            </Button>
                        </Body.NavButtons>
                </Body.Card>
                {/* {privileges?<Body.Card >
                    <Body.MoendaTitle>
                        Gerenciar usuários
                    </Body.MoendaTitle>
                    <Body.NavButtons style={{gridGap: '0px 30px'}} >
                        <Button onClick={()=>toggleRegisterUser(true)}
                            style={{width: '200px'}}>
                            Cadastrar usuário
                        </Button>
                        <Button onClick={()=>toggleChangeUser(true)}
                            style={{width: '200px'}}>
                            Editar usuários
                        </Button>
                    </Body.NavButtons>
                </Body.Card>:null} */}
                
            </Body.HalfContent>
            <Body.HalfContent percentage={49.5}>
                <Body.Card style={{maxHeight: '100%'}}>
                <Body.Row style={{gridGap: '0px 10px'}}>
                    <Body.MoendaTitle>
                            Manutenção
                        </Body.MoendaTitle>
                    </Body.Row>    
                    <Body.Scroll>
                        {!data.unidadeHidraulica.manual?<Body.MaintenanceCard style={{maxHeight: openBomb?"500px":"80px"}}
                            onClick={()=>toggleOpenBomb(old => !old)}>
                            <Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.CircleTitle color={data?.unidadeHidraulica.bomba.situacao == SituacaoBomba.OK ? "#92B258":"#E5D34A"} >
                                    <Body.IconTitle src={data?.unidadeHidraulica.bomba.situacao == SituacaoBomba.OK ? checkIcon:warningIcon} />
                                </Body.CircleTitle>
                                <Body.MaintenanceTitle>
                                    Bombas
                                </Body.MaintenanceTitle>
                            </Body.Row>    
                            <Body.MaintenanceCardIcon src={bombaIcon}/>
                            </Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.GradientContent>
                                    <Body.MoendaText>Transmissor de pressão 1</Body.MoendaText>
                                    <Body.MoendaTitle>{data.unidadeHidraulica.bomba.transmissor_pressao_1.toFixed(0)} psi</Body.MoendaTitle>
                                </Body.GradientContent>
                                <Body.GradientContent>
                                    <Body.MoendaText>Transmissor de pressão 2</Body.MoendaText>
                                    <Body.MoendaTitle>{data.unidadeHidraulica.bomba.transmissor_pressao_2.toFixed(0)} psi</Body.MoendaTitle>
                            </Body.GradientContent>
                            </Body.Row>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                {data.unidadeHidraulica.bomba.transmissor_pressao_3?
                                <Body.GradientContent>
                                    <Body.MoendaText>Transmissor de pressão 3</Body.MoendaText>
                                    <Body.MoendaTitle>{data.unidadeHidraulica.bomba.transmissor_pressao_3.toFixed(0)} psi</Body.MoendaTitle>
                                </Body.GradientContent>:null}
                            </Body.Row>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.GradientContent>
                                    <Body.MoendaText>Autoteste bomba 1</Body.MoendaText>
                                    <Body.GradientCenter />    
                                    <Body.RailPickup>
                                        <Body.Pickup 
                                            left={functions.getAutotestePickup(
                                                data?.unidadeHidraulica.bomba.autoteste_bomba_1, 
                                                data?.unidadeHidraulica.bomba.pressao_max)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.bomba.autoteste_bomba_1.toFixed(0)} psi
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>
                                <Body.GradientContent>
                                    <Body.MoendaText>Autoteste bomba 2</Body.MoendaText>
                                    <Body.GradientCenter />    
                                    <Body.RailPickup>
                                        <Body.Pickup 
                                            left={functions.getAutotestePickup(
                                                data?.unidadeHidraulica.bomba.autoteste_bomba_2, 
                                                data?.unidadeHidraulica.bomba.pressao_max)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.bomba.autoteste_bomba_2.toFixed(0)} psi
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>
                            </Body.Row>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                {data?.unidadeHidraulica.bomba.autoteste_bomba_3?
                                <Body.GradientContent>
                                    <Body.MoendaText>Autoteste bomba 3</Body.MoendaText>
                                    <Body.GradientCenter />    
                                    <Body.RailPickup>
                                        <Body.Pickup 
                                            left={functions.getAutotestePickup(
                                                data?.unidadeHidraulica.bomba.autoteste_bomba_3, 
                                                data?.unidadeHidraulica.bomba.pressao_max)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.bomba.autoteste_bomba_3.toFixed(0)} psi
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>:null}
                            </Body.Row>
                            {/* <Body.GradientContent>
                                <Body.MoendaText>
                                    Data do último autoteste
                                </Body.MoendaText>
                                <Body.Row >
                                    <Body.MoendaTitle>
                                        {data?.unidadeHidraulica.bomba.data}
                                    </Body.MoendaTitle>
                                </Body.Row>
                            </Body.GradientContent> */}
                            <Body.GradientContent>
                                <Body.MoendaText>
                                    Situação
                                </Body.MoendaText>
                                <Body.Row >
                                    <Body.MoendaTitle>
                                        {getTextBomb(data!.unidadeHidraulica.bomba.situacao)}
                                    </Body.MoendaTitle>
                                </Body.Row>
                            </Body.GradientContent>
                        </Body.MaintenanceCard>:null}

                        <Body.MaintenanceCard style={{maxHeight: openOil?"700px":"80px"}}
                            onClick={()=>toggleOpenOil(old => !old)} >
                            <Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.CircleTitle color={data?.unidadeHidraulica.oleo.situacao === Status.OK ? "#92B258":"#E5D34A"} >
                                    <Body.IconTitle src={data?.unidadeHidraulica.oleo.situacao === Status.OK ? checkIcon:warningIcon} />
                                </Body.CircleTitle>
                                <Body.MaintenanceTitle>
                                    Óleo
                                </Body.MaintenanceTitle>
                            </Body.Row>    
                            <Body.MaintenanceCardIcon src={oleoIcon}/>
                            </Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.GradientContent>
                                    <Body.MoendaText>Temperatura</Body.MoendaText>
                                    <Body.GradientCenter />    
                                    <Body.RailPickup>
                                        <Body.Pickup left={functions.getTemperaturePickup(data.unidadeHidraulica.oleo.temperatura)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data.unidadeHidraulica.oleo.temperatura.toFixed(1)} ºC
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>
                                <Body.GradientContent>
                                    <Body.MoendaText>Transmissor de umidade</Body.MoendaText>
                                    <Body.GradientUmidade />    
                                    <Body.RailPickup>
                                        <Body.Pickup left={data.unidadeHidraulica.oleo.trans_umidade}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.oleo.trans_umidade.toFixed(2)} %
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>
                            </Body.Row>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.GradientContent>
                                    <Body.MoendaText style={{marginBottom: '2px'}}>Transmissor de filtro sujo 1</Body.MoendaText>
                                    <Body.GradientStart />    
                                    <Body.RailPickup>
                                        <Body.Pickup left={functions.getSaturacaoStatus(data.unidadeHidraulica.oleo.trans_fil_suj_1)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.oleo.trans_fil_suj_1.toFixed(2)} bar
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>
                                {data?.unidadeHidraulica.oleo.trans_fil_suj_2?<Body.GradientContent>
                                    <Body.MoendaText style={{marginBottom: '2px'}}>Transmissor de filtro sujo 2</Body.MoendaText>
                                    <Body.GradientStart />    
                                    <Body.RailPickup>
                                        <Body.Pickup left={functions.getSaturacaoStatus(data?.unidadeHidraulica.oleo.trans_fil_suj_2)}>
                                            <Body.CirclePickup />
                                            <Body.TextPickup>
                                                {data?.unidadeHidraulica.oleo.trans_fil_suj_2.toFixed(2)} bar
                                            </Body.TextPickup>
                                        </Body.Pickup>
                                    </Body.RailPickup>
                                </Body.GradientContent>:null}
                            </Body.Row>
                            {getAlertsOil(data.unidadeHidraulica)}
                        </Body.MaintenanceCard>

                        <Body.MaintenanceCard style={{maxHeight: openTransmitter?"500px":"80px"}}
                            onClick={()=>toggleOpenTransmitter(old => !old)} >
                            <Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.CircleTitle color={data!.unidadeHidraulica.transmissores?.length === 0? "#92B258":"#E5D34A"} >
                                    <Body.IconTitle src={data!.unidadeHidraulica.transmissores?.length === 0? checkIcon:warningIcon} />
                                </Body.CircleTitle>
                                <Body.MaintenanceTitle>
                                    Transmissores
                                </Body.MaintenanceTitle>
                            </Body.Row>    
                            <Body.MaintenanceCardIcon src={transmissorIcon}/>
                            </Body.MaintenanceInit>
                            {data!.unidadeHidraulica.transmissores && data!.unidadeHidraulica.transmissores?.length === 0?<Body.MoendaText style={{marginBottom: '30px'}}>
                                A manutenção de seus
                                transmissores está em dia
                            </Body.MoendaText>:
                            getTernos(data!.unidadeHidraulica.transmissores!)}
                        </Body.MaintenanceCard>

                        <Body.MaintenanceCard style={{maxHeight: openValve?"500px":"80px"}}
                            onClick={()=>toggleOpenValve(old => !old)}>
                            <Body.MaintenanceInit>
                            <Body.Row style={{gridGap: '0px 10px'}}>
                                <Body.CircleTitle color={data?.unidadeHidraulica.valvulas?.length === 0? "#92B258":"#E5D34A"} >
                                    <Body.IconTitle src={data?.unidadeHidraulica.valvulas?.length === 0? checkIcon:warningIcon} />
                                </Body.CircleTitle>
                                <Body.MaintenanceTitle>
                                    Válvulas
                                </Body.MaintenanceTitle>
                            </Body.Row>    
                            <Body.MaintenanceCardIcon src={valvulaIcon}/>
                            </Body.MaintenanceInit>
                            {data?.unidadeHidraulica.valvulas && data?.unidadeHidraulica.valvulas?.length === 0?<Body.MoendaText style={{marginBottom: '30px'}}>
                                A manutenção de suas
                                válvulas está em dia
                            </Body.MoendaText>:
                            getTernos(data?.unidadeHidraulica.valvulas!)}
                        </Body.MaintenanceCard>

                    </Body.Scroll>
                </Body.Card>
            </Body.HalfContent>
        </Body.Content>}
        {registerUser?<ConfigModals.RegisterUser toggle={toggleRegisterUser} />:null}
        {changeUser?<ConfigModals.EditUsers toggleModal={toggleChangeUser} />:null}
        {/* {modalNotifications?<Modals.NotificationModal toggleModal={toggleModalNotifications} notificacoes={data!.notificacoes} />:null} */}
        {modalInterventions?<Modals.InterventionsModal toggleModal={toggleModalInterventions}
            token={token!} qtdTernos={data!.ternos.length}/>:null}
        {modalRegisterIntervention?<Modals.RegisterIntervention toggleModal={toggleModalRegisterIntervention}
            token={token!} qtdTernos={data!.ternos.length} />:null}
        {/* {modalParametrizationPT?<Modals.ParametrizationTernoModal toggleModal={toggleModalParametrizationPT}
            token={token!} terno={1}/>:
            null} */}
        {modalParametrizationUH?<Modals.ParametrizationUH toggleModal={toggleModalParametrizationUH} 
            token={token!} uh={1}/>:null}
        {modalParametrizationUH2?<Modals.ParametrizationUH toggleModal={toggleModalParametrizationUH2} 
            token={token!} uh={2}/>:null}
        {modalPerformance?<Modals.Performance toggleModal={togglePerformance} ternos={data!.ternos} />:null}
        {visualizeTerno?<Modals.VisualizarTerno toggleModal={toggleVisualizeTerno} 
            terno={terno!}/>:null}
        {modalLimits?<Modals.DefineLimits toggleModal={toggleModalLimits} ternos={data!.ternos} token={token!}
            id={ternoId} isUH2={isUH2} />:null}
        {loading?<Loading />:null}
        <Body.ContentTimeUpdate>
            <Body.ListText>{seeIsRequesting?"Atualizando dados, aguarde...":`Atualizando dados em ${functions.adjustTime(seeTime)}`}</Body.ListText>
        </Body.ContentTimeUpdate>
    </Body.Container>
}

export default Moenda